$bg-color: #00873e;
$candy-color: #d33144, #ffffee;
$christmas-color: $bg-color;
$anim-time: 8s;
$candy-w: 100px;
$candy-h: 300px;

#candy-loader-main {
  background-color: $bg-color;
  animation: color $anim-time linear infinite;
}

#candy {
  overflow: hidden;
  display: flex;
  opacity: 0;
  margin: calc(50vh - 150px) auto 0 auto;
  width: $candy-w;
  height: $candy-h;
  border-radius: $candy-w;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: $bg-color;
  box-shadow: 40px 20px 15px -30px rgba(255, 0, 0, 0.1);
  transform: rotate(90deg);
  animation: PARTY $anim-time cubic-bezier(0.87, -0.41, 0.19, 1.44) infinite;
}

#candy-loader {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  align-self: flex-end;
  box-shadow: 0px 0px 10px rgba(255, 50, 0, 0.8) inset;
  background: nth($candy-color, 1);
  background: repeating-linear-gradient(
    45deg,
    nth($candy-color, 2),
    nth($candy-color, 2) 15px,
    nth($candy-color, 1) 15px,
    nth($candy-color, 1) 30px
  );
  animation: load $anim-time ease-in infinite;
  &::after {
    content: '';
    background-color: $bg-color;
    width: $candy-w/2;
    height: $candy-h - ($candy-h/10);
    border-radius: 50px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    align-self: flex-end;
    animation: color $anim-time linear infinite;
  }
  &::before {
    content: '';
    background-color: $bg-color;
    width: $candy-w/4;
    height: $candy-h * 0.7;
    display: flex;
    align-self: flex-end;
    animation: color $anim-time linear infinite;
  }
}

@keyframes load {
  0% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
}

@keyframes PARTY {
  5% {
    opacity: 1;
  }
  50% {
    transform: rotate(90deg) scale(1);
  }
  60% {
    transform: rotate(-10deg) scale(1);
  }
  70% {
    transform: rotate(20deg) scale(1);
  }
  80% {
    transform: rotate(-10deg) scale(1);
  }
  90% {
    transform: rotate(30deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: rotate(-180deg) scale(0);
    opacity: 0;
  }
}

@keyframes color {
  55% {
    background-color: $bg-color;
  }
  57% {
    background-color: $christmas-color;
  }
  95% {
    background-color: $christmas-color;
  }
}
