.error-message-body {
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    text-align: center;
  }

  img {
    width: 50%;
  }
}
