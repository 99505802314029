$christmas-green: #00873e;
$christmas-red: #c54245;
@mixin flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main {
  background-color: transparent;
  margin-top: 10px;

  h2 {
    color: #c54245;
  }

  .paricipant-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 25px;
  }

  .paricipant-name {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .form-group {
      margin: 0;
    }
  }

  .pick-names-link {
    text-decoration: underline;
    color: $christmas-green;
  }

  .participant {
    font-weight: bold;
  }

  .buyer {
    color: $christmas-red;
  }

  .buying-for {
    color: $christmas-green;
  }

  .secret-santa-switcher {
    @include flexRow;
  }

  .choose-spouse {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .spouse-select {
    font-size: small;
  }

  .spouse-help {
    margin-left: 10px;
  }
}
